import { lazy } from 'react';
//
//
import {
    adminAppRoot,
    investorAppRoot,
    authRoute,
    clientAppRoot,
    businessAppRoot,
    bankingPlaidCallbackRoute,
    clientBankingHandlerRoute,
    secondaryInvestor,
    userTypeSelect,
} from 'constants.ts';
import { App } from 'App.tsx';

const ClientApp = lazy(() => import('Apps/client-app'));
const InvestorApp = lazy(() => import('Apps/investor-app/AppRoot.tsx'));
const BusinessApp = lazy(() => import('Apps/business-app/AppRoot.tsx'));
const AdminPanel = lazy(() => import('Apps/admin-panel'));

const UserHandler = lazy(
    () => import('Apps/client-app/components/UserHandler.tsx')
);
const ClienBankingHandler = lazy(
    () => import('Apps/client-app/components/ClientBankingHandler.tsx')
);
import { Auth } from 'Apps/client-app/Auth';
import {
    pendingDealNewPath,
    pendingDealPath,
    pendingOwnerPath,
} from 'Apps/admin-panel/constants.ts';
import { ExternalLinks } from 'Components/ExternalLinks.tsx';
import { SecondaryInvestorInvite } from './Apps/client-app/SecondaryInvestorInvite.tsx';
import { UserTypeSelect } from './Apps/client-app/UserTypeSelect.tsx';

export const routes = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: clientAppRoot,
                element: <ClientApp />,
                children: [
                    { index: true, element: <UserHandler /> },
                    {
                        path: clientBankingHandlerRoute,
                        element: <ClienBankingHandler />,
                    },
                    {
                        path: investorAppRoot + '/*',
                        element: <InvestorApp />,
                    },
                    {
                        path: businessAppRoot + '/*',
                        element: <BusinessApp />,
                    },
                    {
                        path: userTypeSelect,
                        element: <UserTypeSelect />,
                    },
                ],
            },
            {
                path: secondaryInvestor,
                element: <SecondaryInvestorInvite />,
            },
            {
                path: adminAppRoot + '/*',
                element: <AdminPanel />,
            },
            {
                path: authRoute,
                element: <Auth />,
            },

            //external links, change it carefully
            {
                path: 'pending-owner/:auth0id',
                element: (
                    <ExternalLinks<'auth0id'>>
                        {(params, search, hash) =>
                            ExternalLinks.redirect({
                                pathname: pendingOwnerPath(params.auth0id),
                                search,
                                hash,
                            })
                        }
                    </ExternalLinks>
                ),
            },
            {
                path: 'deal-request/:auth0id',
                element: (
                    <ExternalLinks<'auth0id'>>
                        {(params, search, hash) =>
                            ExternalLinks.redirect({
                                pathname: pendingDealNewPath(params.auth0id),
                                search,
                                hash,
                            })
                        }
                    </ExternalLinks>
                ),
            },
            {
                path: 'pending-deal/:dealId',
                element: (
                    <ExternalLinks<'dealId'>>
                        {(params, search, hash) =>
                            ExternalLinks.redirect({
                                pathname: pendingDealPath(params.dealId || ''),
                                search,
                                hash,
                            })
                        }
                    </ExternalLinks>
                ),
            },
            {
                path: 'entity-investor',
                element: (
                    <ExternalLinks>
                        {() =>
                            ExternalLinks.redirect({
                                pathname: secondaryInvestor,
                            })
                        }
                    </ExternalLinks>
                ),
            },
            {
                path: bankingPlaidCallbackRoute,
                element: <ExternalLinks redirect={clientBankingHandlerRoute} />,
            },
            //
            {
                path: '*',
                element: <>404</>,
            },
        ],
        errorElement: <div>An error occurred</div>,
    },
];
